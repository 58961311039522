import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Nilrio</title>
        <meta
          name="description"
          content="Nilrio une marque française Vêtements pour hommes et accessoires"
        />
        <meta
          name="keywords"
          content="Nilrio Tshirt, T-shirt Nilrio, Vêtements Nilrio, Mode Nilrio, Marque de T-shirts France, T-shirts de qualité France, Vêtements élégants France, Boutique Nilrio France"
        />
        <meta name="robots" content="index, follow" />
        <meta name="referrer" content="origin-when-cross-origin" />
        <meta name="generator" content="AYDTANITIM" />
        <meta name="application-name" content="Next.js" />
        <meta name="creator" content="Aydtanitim.com" />
        <meta name="publisher" content="Nilrio" />

        {/* Open Graph */}
        <meta property="og:title" content="Nilrio" />
        <meta
          property="og:description"
          content="Nilrio une marque française Vêtements pour hommes et accessoires"
        />
        <meta property="og:url" content="https://nilrio.com" />
        <meta property="og:site_name" content="Nilrio" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dslul2smn/image/upload/v1726661804/nilrio/an7z4hgnsvz3dtupluxw.png"
        />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />

        {/* Link Rel Canonical */}
        <link rel="canonical" href="https://nilrio.com" />
      </Helmet>
      <div className="w-screen h-screen bg-black flex items-center justify-center">
        <img src="/bakimda.webp" alt="Nilrio" className="object-contain" />
      </div>
    </>
  );
}

export default App;
